import Link from 'next/link';
import { useEffect, useState } from 'react';
import { GTM_ID } from '../../lib/config';
import { useTranslation } from '../../lib/i18n';
import { getData, setData } from '../../lib/storage';
import { isUserBrowser } from '../../lib/user-agent';
import { BUTTON_EMPTY_STYLE, BUTTON_FILLED_STYLE } from '../common/button';

// re-implement with https://developers.google.com/gtagjs/devguide/consent

export const PrivacyDialog = ({ onAccept, className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`z-50 w-full border-t border-black bg-white p-8 ${className}`}
    >
      <div className='bottom-0 mx-auto max-w-4xl items-center sm:flex sm:gap-8'>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                'Wir verwenden Cookies, um dir das beste online Erlebnis zu bieten Bitte lass uns wissen, ob du mit unsere Cookies einverstanden bist Zu Cookies empfehlen wir übrigens Portwein',
              ),
            }}
          />
          <Link
            href='/datenschutz'
            prefetch={false}
            className='link mt-2 block text-green hover:underline'
          >
            {t('Datenschutzerklärung lesen')} »
          </Link>
        </div>
        <div>
          <button
            className={`${BUTTON_FILLED_STYLE} mt-4 w-full px-3 text-sm`}
            onClick={() => onAccept('all')}
          >
            {t('Alle akzeptieren')}
          </button>

          <button
            className={`${BUTTON_EMPTY_STYLE} mt-4 w-full px-3 text-sm`}
            onClick={() => onAccept('essential')}
          >
            {t('Nur Essenzielle akzeptieren')}
          </button>
        </div>
      </div>
    </div>
  );
};

export const TAG_MANAGER_INNER_HTML_FULL = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');
`;

const INLINE_ID = 'tagManagerInline';

export const TagManager = () => {
  useEffect(() => {
    const scriptInline = document.createElement('script');
    scriptInline.setAttribute('id', INLINE_ID);
    scriptInline.async = true;
    scriptInline.innerHTML = TAG_MANAGER_INNER_HTML_FULL;
    document.head.appendChild(scriptInline);
  }, []);

  return <></>;
};

export const Privacy = ({ className }) => {
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(false);
  const [accepted, setAccepted] = useState('not-set');

  useEffect(() => {
    setLoaded(isUserBrowser() && !getData('noExternalScripts', false, true));
    setAccepted(getData('privacyAccepted', 'not-set', true));
  }, []);

  const onAccept = (type) => {
    setData('privacyAccepted', type, true);
    setAccepted(type);
  };

  if (!loaded) {
    return <div></div>;
  }

  if (accepted === 'essential') {
    return <></>;
  }

  if (accepted === 'all') {
    if (GTM_ID && typeof window !== 'undefined') {
      return <TagManager />;
    }
    return <></>;
  }

  return (
    <PrivacyDialog className={className} onAccept={onAccept}></PrivacyDialog>
  );
};
