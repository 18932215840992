import { Transition } from '@headlessui/react';
import { useCombobox } from 'downshift';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { search } from '../../lib/api';
import { useTranslation } from '../../lib/i18n';
import { ReadMoreLink } from '../common/link';

// const searchDebounced = debounce(
//   (val, setResults) => {
//     search(`q=${encodeURIComponent(val)}&dt=data_teaser_mini&size=10`).then(
//       (results) => {
//         setResults(results.results);
//       },
//     );
//   },
//   500,
//   true,
// );

const searchDebounced = (val, setResults) => {
  search(`q=${encodeURIComponent(val)}&dt=data_teaser_mini&size=10`).then(
    (results) => {
      setResults(results.results);
    },
  );
};

export const SearchInput = ({
  className = '',
  inputClassName = '',
  children = undefined,
  placeholder = undefined,
  onSearch = undefined,
  ...props
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [suggestions, setSuggestions] = useState([]);

  if (!placeholder) {
    placeholder = t('Suche');
  }

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
  } = useCombobox({
    onInputValueChange: ({ inputValue }) => {
      if (inputValue && inputValue.length > 3) {
        searchDebounced(inputValue, setSuggestions);
      } else {
        setSuggestions([]);
      }
    },
    // ts-ignore
    onSelectedItemChange: ({ selectedItem: { parent_slug, slug } }) => {
      const href = parent_slug ? `/${parent_slug}/${slug}` : slug;
      router.push(href);
      if (onSearch) {
        onSearch();
      }
    },
    items: suggestions,
    itemToString(item) {
      return item ? item.title : '';
    },
  });

  const showMenu = isOpen && suggestions.length > 0;

  return (
    <div className={`${className} relative`}>
      <input
        {...getInputProps({
          className: inputClassName,
          placeholder: placeholder,
          onKeyUp: (event) => {
            if (event.key === 'Enter') {
              router.push('/suchen?q=' + encodeURIComponent(inputValue));
              setSuggestions([]);
              if (onSearch) {
                onSearch();
              }
            }
          },
          ...props,
        })}
      />

      <ul
        {...getMenuProps()}
        className={`absolute w-full bg-white py-4 shadow-lg ${
          showMenu ? '' : 'hidden'
        }`}
      >
        <Transition
          show={showMenu}
          enter='transition ease-out duration-150'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-100'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <div>
            {suggestions.map((item, index) => (
              <li
                key={index}
                {...getItemProps({
                  item,
                  className: `py-2 px-3 cursor-pointer ${
                    highlightedIndex === index ? 'bg-green text-white' : ''
                  }`,
                })}
              >
                <span className='block text-sm'>
                  {item.type == 'collection' ? t(item.category) : item.category}
                </span>
                <span className='block overflow-hidden overflow-ellipsis whitespace-nowrap'>
                  {item.title}
                </span>
              </li>
            ))}

            <li>
              <ReadMoreLink
                className='px-3 py-2'
                label={t('Alle Ergebnisse anzeigen')}
                onClick={() =>
                  router.push('/suchen?q=' + encodeURIComponent(inputValue))
                }
              />
            </li>
          </div>
        </Transition>
      </ul>
      {children && children}
    </div>
  );
};
