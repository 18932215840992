import { useContext, useEffect, useState } from 'react';
import { useTranslation } from '../../lib/i18n';
import { only } from '../../lib/utils';
import { CartOverlayContext } from '../cart/cart-overlay';
import { useUser } from '../user-context';

export const CartIcon = ({ children = null, className = '' }) => {
  const { t } = useTranslation();
  const { totalItemsCount } = useUser();
  const [count, setCount] = useState(0);
  const overlayContext = useContext(CartOverlayContext);

  useEffect(() => {
    setCount(totalItemsCount);
  });

  return (
    <button
      aria-label={t('Warenkorb')}
      className={
        children
          ? className
          : `relative flex items-center justify-center ${className}`
      }
      onClick={only(() => overlayContext.showCart())}
    >
      {children ? (
        <>{children}</>
      ) : (
        <>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1 0.893927C0.668629 0.893927 0.4 1.16256 0.4 1.49393C0.4 1.8253 0.668629 2.09393 1 2.09393V0.893927ZM2.91045 1.49393L3.48958 1.33708C3.41875 1.07554 3.18141 0.893927 2.91045 0.893927V1.49393ZM6.01492 12.9566L5.43579 13.1135C5.51395 13.4021 5.79266 13.5891 6.08935 13.552L6.01492 12.9566ZM15.5672 11.7626L15.6416 12.358C15.8964 12.3261 16.1027 12.1356 16.1547 11.8841L15.5672 11.7626ZM17 4.83721L17.5876 4.95878C17.6241 4.78207 17.5792 4.59829 17.4653 4.45836C17.3513 4.31843 17.1804 4.23721 17 4.23721V4.83721ZM3.81592 4.83721L3.23678 4.99406L3.23678 4.99406L3.81592 4.83721ZM1 2.09393H2.91045V0.893927H1V2.09393ZM6.08935 13.552L15.6416 12.358L15.4927 11.1672L5.9405 12.3613L6.08935 13.552ZM16.1547 11.8841L17.5876 4.95878L16.4124 4.71565L14.9796 11.641L16.1547 11.8841ZM2.33131 1.65078L3.23678 4.99406L4.39506 4.68036L3.48958 1.33708L2.33131 1.65078ZM3.23678 4.99406L5.43579 13.1135L6.59406 12.7998L4.39506 4.68036L3.23678 4.99406ZM17 4.23721H3.81592V5.43721H17V4.23721ZM8.4 16.0833C8.4 16.4423 8.10898 16.7333 7.75 16.7333V17.9333C8.77173 17.9333 9.6 17.105 9.6 16.0833H8.4ZM7.75 16.7333C7.39101 16.7333 7.1 16.4423 7.1 16.0833H5.9C5.9 17.105 6.72827 17.9333 7.75 17.9333V16.7333ZM7.1 16.0833C7.1 15.7243 7.39101 15.4333 7.75 15.4333V14.2333C6.72827 14.2333 5.9 15.0616 5.9 16.0833H7.1ZM7.75 15.4333C8.10898 15.4333 8.4 15.7243 8.4 16.0833H9.6C9.6 15.0616 8.77173 14.2333 7.75 14.2333V15.4333ZM15.0667 16.0833C15.0667 16.4423 14.7757 16.7333 14.4167 16.7333V17.9333C15.4384 17.9333 16.2667 17.105 16.2667 16.0833H15.0667ZM14.4167 16.7333C14.0577 16.7333 13.7667 16.4423 13.7667 16.0833H12.5667C12.5667 17.105 13.3949 17.9333 14.4167 17.9333V16.7333ZM13.7667 16.0833C13.7667 15.7243 14.0577 15.4333 14.4167 15.4333V14.2333C13.3949 14.2333 12.5667 15.0616 12.5667 16.0833H13.7667ZM14.4167 15.4333C14.7757 15.4333 15.0667 15.7243 15.0667 16.0833H16.2667C16.2667 15.0616 15.4384 14.2333 14.4167 14.2333V15.4333Z'
              fill='black'
            />
          </svg>

          {count > 0 && (
            <div className='absolute -right-3 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-actionRed text-sm text-white'>
              <div className='mt-px'>{count}</div>
            </div>
          )}
        </>
      )}
    </button>
  );
};
