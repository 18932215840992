import Link from 'next/link';
import { getSvg } from '../../generated/svg';
import { IS_DEFAULT_CHANNEL } from '../../lib/config';
import { useTranslatedNavigation, useTranslation } from '../../lib/i18n';

const FooterItem = ({
  img,
  scroll = true,
  href = '',
  title,
  text = '',
  className = '',
}) => (
  <Link
    href={href}
    scroll={scroll}
    prefetch={false}
    className='hover:text-green'
  >
    <div className={`${className} flex items-start text-left`}>
      <div className='min-w-[28px]'>
        <img alt='' src={getSvg(img)} />
      </div>
      <div className='ml-3'>
        <span className='font-bold uppercase'>{title}</span>
        {img === 'nav_payment' && IS_DEFAULT_CHANNEL ? (
          <>
            {' '}
            <img
              alt=''
              src={'/static/payments-small-invoice.svg'}
              className='mt-2 hidden h-[19px] md:block'
            />
            <p className='mt-2 block md:hidden'>{text}</p>
          </>
        ) : (
          <p className='mt-2'>{text}</p>
        )}
      </div>
    </div>
  </Link>
);

const SocialMediaItem = ({ img, href = '', className = '', label = '' }) => (
  <Link
    href={href}
    prefetch={false}
    className={className}
    aria-label={label}
    target='_blank'
    rel='noopener noreferrer'
  >
    <img alt={label} src={img} />
  </Link>
);

export const Footer = ({ query }) => {
  const { t } = useTranslation();
  const isFaq = query?.slug === 'faq';

  const navigation = useTranslatedNavigation();

  return (
    <div className='mx-auto mt-20 grid max-w-4xl p-10 text-left print:hidden'>
      <ul className='grid grid-cols-1 gap-10 md:grid-cols-2'>
        {navigation.helpFooter.map(
          ({ title, icon, text, link: { href } }, key) => (
            <li key={key}>
              <FooterItem
                href={href}
                img={icon}
                scroll={isFaq}
                title={title}
                text={text}
              />
            </li>
          ),
        )}
      </ul>

      <div className='ml-[39px] mt-6 grid grid-cols-1 gap-x-8 gap-y-2 md:ml-0 md:mt-10 md:grid-cols-2'>
        <ul className='flex flex-col gap-6 md:ml-[39px] md:gap-2'>
          {navigation.footer.map(
            ({ text, link: { href, target, rel } }, key) => (
              <li key={key}>
                <Link
                  href={href}
                  className='font-bold uppercase hover:text-green'
                  target={target}
                  rel={rel}
                >
                  {text}
                </Link>
              </li>
            ),
          )}
        </ul>
        <ul className='mt-6 flex flex-col gap-6 md:ml-[45px] md:mt-0 md:gap-2'>
          {navigation.legalFooter.map(({ text, link: { href } }, key) => (
            <li key={key}>
              <Link
                href={href}
                className='font-bold uppercase hover:text-green'
              >
                {text}
              </Link>
            </li>
          ))}
          <li>
            <a href='https://wka.ch/' target='_blank' rel='noopener noreferrer'>
              {t('copyright')}
            </a>
          </li>
          <li>
            <ul className='mt-1 flex gap-6 md:gap-2'>
              {navigation.socialMediaFooter.map(
                ({ label, icon, link: { href } }, key) => (
                  <li key={key}>
                    <SocialMediaItem
                      img={getSvg(icon)}
                      href={href}
                      label={label}
                    />
                  </li>
                ),
              )}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};
